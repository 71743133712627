/*
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-17 14:02:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-06-30 11:21:54
 */
/**
* api接口统一管理
*/
import { axios } from '@/utils/request'

export const listDealerCustomerOrder = params => axios({
    url: '/api/dealer/customer/dealerCustomerOrder/list',
    method: 'get',
    params,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const addDealerCustomerOrder = params => axios({
    url: '/api/dealer/customer/dealerCustomerOrder/add',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const editDealerCustomerOrder = params => axios({
    url: '/api/dealer/customer/dealerCustomerOrder/edit',
    method: 'post',
    data: JSON.stringify(params),
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

export const applyAgain = params => axios({
  url: '/api/dealer/customer/customerOrder/applyAgain',
  method: 'post',
  params,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})


export const delDealerCustomerOrder = params => axios({
    url:'/api/dealer/customer/dealerCustomerOrder/del/' + params,
    method: 'post',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const selectByIdDealerCustomerOrder = params => axios({
    url: '/api/dealer/customer/dealerCustomerOrder/selectById/' + params,
    method: 'get',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})
